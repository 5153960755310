<template>
  <div>
    <div />
    <nav>
      <RouterLink :to="{ name: 'index' }">
        <img
          src="/images/stilles.jpg"
          alt="Stilles"
        >
      </RouterLink>
    </nav>
    <main>
      <slot />
    </main>
    <vSvgSprite />
  </div>
</template>

<style scoped>
nav {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  max-width: 1488px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

img {
  width: 110px;
  height: auto;
  vertical-align: middle;
}
</style>
